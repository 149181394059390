
// @ is an alias to /sr
import {
  computed,
  defineComponent,
  inject,
  onBeforeMount,
  reactive,
  ref,
  toRefs,
} from "vue";
import AfterLoginPageLayout from "@/views/layouts/AfterLoginPageLayout.vue";
import FlatButton from "@/components/atomics/FlatButton.vue";
import BorderButton from "@/components/atomics/BorderButton.vue";
import ValidationTextField from "@/components/atomics/ValidationTextField.vue";
import Chip from "@/components/atomics/Chip.vue";
import BreadScrum from "@/components/atomics/BreadScrum.vue";
import AvatarCircle from "@/components/organisms/AvatarCircle.vue";
import { GroupRepository, RepositoryFactory } from "@/lib/https";
import { useForm } from "vee-validate";
import router from "@/router";
import { getFilePathFromUrl } from "@/lib/utility/stringUtil";
import { useI18n } from "vue-i18n";
export default defineComponent({
  name: "GroupAdd",
  components: {
    AfterLoginPageLayout,
    FlatButton,
    BorderButton,
    ValidationTextField,
    Chip,
    BreadScrum,
    AvatarCircle,
  },
  setup() {
    const { t } = useI18n();
    const state = reactive({
      breadScrums: [
        { text: t("groupAdd.headerTitle"), to: "/group/group-add" },
      ],
    });

    const users = ref([] as Array<any>);
    const selectedUsers = ref([] as Array<any>);
    const isMobile = inject("isMobile");

    const searchKeyword = ref("");
    let delay: ReturnType<typeof setTimeout>;
    let page = 1;
    let total = 0;

    const backToGroup = () => {
      router.push({ name: "Group" });
    };

    const search = () => {
      if (delay) clearTimeout(delay);
      delay = setTimeout(async () => {
        page  = 1;
        const payload = await getUserList({
          page: page,
          keyword: searchKeyword.value,
        });
        users.value = payload.data as Array<any>;
        total = payload.pagination.totalRecords;
      }, 300);
    };

    const loadMoreMembers = async (e: any) => {
      const bottomOfWindow =
        Math.abs(
          e.target.scrollTop + e.target.offsetHeight - e.target.scrollHeight
        ) < 1;
      let canLoadMore = users.value?.length < total;
      if (bottomOfWindow && canLoadMore) {
        page = page + 1;
        const moreUsers = await getUserList({
          page: page,
          keyword: searchKeyword.value,
        });
        users.value.push(...(moreUsers.data as Array<any>));
      }
    };

    const { getUserList, createGroup } = groupAddMethods();
    const { submit } = formHandler(selectedUsers, createGroup, backToGroup);

    const isSelected = (item: any) =>
      selectedUsers.value.find((user: any) => user.id === item.id);

    onBeforeMount(async () => {
      const payload = await getUserList({});
      users.value = payload.data as Array<any>;
      total = payload.pagination.totalRecords;
    });

    return {
      ...toRefs(state),
      users,
      selectedUsers,
      submit,
      backToGroup,
      search,
      searchKeyword,
      loadMoreMembers,
      isSelected,
      isMobile,
      getFilePathFromUrl,
    };
  },
  methods: {
    selectUser(index: number) {
      const isContain = this.selectedUsers.find(
        (element: any) => element.id === (this.users[index] as any).id
      );

      if (!isContain) {
        this.selectedUsers.push(this.users[index]);
      }
    },
    removeSelectedUser(index: number) {
      this.selectedUsers.splice(index, 1);
    },
  },
});

const formHandler = (
  members: any,
  onSubmit: CallableFunction,
  onSuccess: CallableFunction
) => {
  const { values, errors, submitForm, setErrors } = useForm();

  const submit = () => {
    submitForm()
      .then(async () => {
        if (errors.value["groupName"]) return;

        const memberList = [] as never[];
        members.value.forEach((element: any) => {
          memberList.push(element.id as never);
        });

        const data = {
          members: memberList,
          name: values["groupName"],
          description: values["groupDescription"] ?? "",
        };

        if (await onSubmit(data)) onSuccess();
      })
      .catch((e) => {
        setErrors({
          groupName: e.data.message,
        });
      });
  };

  return { submit };
};

const groupAddMethods = () => {
  const { getUserList, createGroup } =
    RepositoryFactory.getRepository<GroupRepository>(GroupRepository);

  return {
    getUserList,
    createGroup,
  };
};
